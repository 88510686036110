import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useValidator from '../../utilities/useValidator';
import { communication } from '../../services/communication';
import { addUserData } from "../../redux-store/user-slice";
import swal from 'sweetalert';
import { showLoader, hideLoader } from '../../redux-store/loader-slice';
import Loader from '../../utilities/Loader';

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [validator, showValidationMessage] = useValidator();
    const [userData, setUserData] = useState({
        userId: "",
        password: ""
    });

    const loader = useSelector((state) => state.loader);

    //Login to Shop Dashboard
    const login = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            //call API
            const serverResponse = await communication.login(userData);
            if (serverResponse.status === "SUCCESS") {
                dispatch(hideLoader());
                dispatch(addUserData({ userDetails: serverResponse.userDetails, token: serverResponse.token }))
                navigate("/shop-dashboard/generate-bill");
                swal({ text: serverResponse.message, icon: "success" });
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });

            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }

    }
    return (
        <div className="sign_in_section">
            <div className="sign_in_form_main">
                <div className="company_details">
                    <h1>Jewellery Billing SoftWare</h1>
                    <p>em@il : hello@rsinfotechsys.com</p>
                    {/* <p>mobile : 7972192831</p> */}
                </div>
                <div className="sign_in_form">
                    <h2>Shop Login</h2>
                    <div className="input_field">
                        <input type="text" placeholder='User ID' value={userData.userId} onChange={(e) => setUserData({ ...userData, userId: (e.target.value).trim() })} />
                        {validator.message("userId", userData.userId, "required", {
                            messages: {
                                required: "UserId is required"
                            }
                        })}
                    </div>
                    <div className="input_field">
                        <input type="password" placeholder='Password' value={userData.password} onChange={(e) => setUserData({ ...userData, password: (e.target.value).trim() })} />
                        {validator.message("password", userData.password, "required|min:8", {
                            messages: {
                                required: "Password is required"
                            }
                        })}
                    </div>
                    {loader.loaderState === true ?
                        <button><Loader /></button>
                        :
                        <button onClick={() => login()}>Login</button>
                    }

                </div>
            </div>
        </div>
    )
}

export default SignIn