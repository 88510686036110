import { configureStore } from "@reduxjs/toolkit";
import userDetails from "./user-slice";
import adminDetails from "./admin-slice";
import loaderSlice from "./loader-slice";

const store = configureStore({
    reducer: {
        userDetails:userDetails,
        adminDetails:adminDetails,
        loader:loaderSlice
    }
})

export default store;