import React, { useState } from 'react'
import Button from '../../../common/Button';
import InputField from '../../../common/InputField';
import { adminCommunication } from '../../../services/adminCommunication';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import useValidator from '../../../utilities/useValidator';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux-store/loader-slice';
import Loader from '../../../utilities/Loader';

const CreateAccountForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const [data, setData] = useState(
        {
            ownerName: "",
            address: "",
            city: "",
            state: "",
            country: "",
            pinCode: "",
            shopName: "",
            gstNumber: "",
            registrationDate: "",
            autoKeyGenerate: "",
            totalDays: "",
            amount: ""
        }
    );
    const [validator, showValidationMessage] = useValidator();

    //create User
    async function createUser() {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const dataToSend = {
                owner: data.ownerName,
                shopName: data.shopName,
                address: data.address,
                city: data.city,
                state: data.state,
                country: data.country,
                pinCode: data.pinCode,
                registrationDate: data.registrationDate,
                amount: data.amount,
                totalMonth: data.totalDays,
                gstNumber: data.gstNumber,
                mobile: data.mobileNumber,
            }

            //call API
            const serverResponse = await adminCommunication.createUsers(dataToSend);
            if (serverResponse.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "success" });
            } else if (serverResponse.status === "JWT_INVALID") {
                dispatch(hideLoader());
                navigate("/admin/login")
                swal({ text: serverResponse.message, icon: "warning" });
            }
            else {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    return (
        <>
            <div className="input_row">
                <div className="input_box">
                    <InputField type="text" name="ownerName" value={data?.ownerName} onChange={(event) => setData({ ...data, ownerName: event.target.value })} placeholder="Shop Owner Name" />
                    {validator.message("owner", data.ownerName, "required", {
                        messages: {
                            required: "Owner is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="text" name="address" value={data?.address} onChange={(event) => setData({ ...data, address: event.target.value })} placeholder="Address" />
                    {validator.message("address", data.address, "required", {
                        messages: {
                            required: "address is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="text" name="city" value={data?.city} onChange={(event) => setData({ ...data, city: event.target.value })} placeholder="City" />
                    {validator.message("city", data.city, "required", {
                        messages: {
                            required: "city is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="text" name="state" value={data?.state} onChange={(event) => setData({ ...data, state: event.target.value })} placeholder="State" />
                    {validator.message("state", data.state, "required", {
                        messages: {
                            required: "state is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="text" name="country" value={data?.country} onChange={(event) => setData({ ...data, country: event.target.value })} placeholder="Country" />
                    {validator.message("country", data.country, "required", {
                        messages: {
                            required: "country is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="number" name="pinCode" value={data?.pinCode} onChange={(event) => setData({ ...data, pinCode: event.target.value })} placeholder="PinCode" />
                    {validator.message("pinCode", data.pinCode, "required|numeric|min:6|max:6", {
                        messages: {
                            required: "pinCode is required"
                        }
                    })}
                </div>
            </div>
            <div className="input_row">
                <div className="input_box">
                    <InputField type="text" name="shopName" value={data?.shopName} onChange={(event) => setData({ ...data, shopName: event.target.value })} placeholder="Shop Name" />
                    {validator.message("shopName", data.shopName, "required", {
                        messages: {
                            required: "shopName is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="text" name="gstNumber" value={data?.gstNumber} onChange={(event) => setData({ ...data, gstNumber: event.target.value })} placeholder="GST Number" />
                    {validator.message("gstNumber", data.gstNumber, "required", {
                        messages: {
                            required: "gst Number is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="date" name="registrationDate" value={data?.registrationDate} onChange={(event) => setData({ ...data, registrationDate: event.target.value })} placeholder="Registration Date" />
                    {validator.message("registrationDate", data.registrationDate, "required", {
                        messages: {
                            required: "registrationDate is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="number" name="mobileNumber" value={data?.mobileNumber} onChange={(event) => setData({ ...data, mobileNumber: event.target.value })} placeholder="Mobile No" />
                    {validator.message("mobileNumber", data.mobileNumber, "required|numeric|min:10:max:10", {
                        messages: {
                            required: "Mobile Number is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="number" name="totalDays" value={data?.totalDays} onChange={(event) => setData({ ...data, totalDays: event.target.value })} placeholder="Total Months" />
                    {validator.message("totalDays", data.totalDays, "required|numeric", {
                        messages: {
                            required: "totalDays is required"
                        }
                    })}
                </div>
                <div className="input_box">
                    <InputField type="number" name="amount" value={data?.amount} onChange={(event) => setData({ ...data, amount: event.target.value })} placeholder="Amount" />
                    {validator.message("amount", data.amount, "required", {
                        messages: {
                            required: "amount is required"
                        }
                    })}
                </div>
            </div>
            <div className="button_group">
                {loader.loaderState === false ?
                    <Button name="create a/c" onClick={() => createUser()} />
                    :
                    <Button><Loader /></Button>
                }
            </div>
        </>
    )
}

export default CreateAccountForm