import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

let decodeValue = null;
//**********************Helper Function ********************//
(function getcokkies() {
  // Split cookie string and get all individual valueName=value pairs in an array
  const cookieArr = document.cookie.split(";");

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");

    // Decode the cookie value and return
    if ("jewUserToken" === cookiePair[0].trim()) {
      decodeValue = jwtDecode(cookiePair[1]);
      return decodeValue;
    }
  }
})();
//**********************Helper Function ********************//


const userDetails = createSlice({
  name: "userDetails",
  initialState: {
    userId: decodeValue?.userId ?? "",
    owner: decodeValue?.owner ?? "",
    shopName: decodeValue?.shopName ?? "",
    city: decodeValue?.city ?? "",
    state: decodeValue?.state ?? "",
    country: decodeValue?.country ?? "",
    pinCode: decodeValue?.pinCode ?? "",
    gstNumber: decodeValue?.gstNumber ?? "",
    mobile: decodeValue?.mobile ?? "",
    expirationDate: decodeValue?.expirationDate ?? "",
  },
  reducers: {
    addUserData: (state, action) => {

      state.userId = action.payload.userDetails.userId;
      state.owner = action.payload.userDetails.owner;
      state.shopName = action.payload.userDetails.shopName;
      state.city = action.payload.userDetails.city;
      state.state = action.payload.userDetails.state;
      state.country = action.payload.userDetails.country;
      state.pinCode = action.payload.userDetails.pinCode;
      state.gstNumber = action.payload.userDetails.gstNumber;
      state.mobile = action.payload.userDetails.mobile;
      state.expirationDate = action.payload.userDetails.expirationDate
      document.cookie = `jewUserToken=${action.payload.token};path=/`;
    },
    removeCookies: (state, action) => {
      state.owner = "";
      state.shopName = "";
      state.city = "";
      state.state = "";
      state.country = "";
      state.pinCode = "";
      state.gstNumber = "";
      state.mobile = "";
      state.expirationDate = "";
      document.cookie = `jewUserToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    },
  },
});

export const { addUserData, removeCookies } = userDetails.actions;
export default userDetails.reducer;
