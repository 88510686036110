import React from "react";
import { Routes, Route } from "react-router-dom";
import AccountPage from "../pages/admin-dashboard/account-detail/AccountPage";
import SignIn from "../pages/clientSignIn/SignIn";
import Dashboard from "../pages/shop-dashboard/Dashboard";
import BillPrintView from "../pages/shop-dashboard/generate-bill/BillPrintView";
import GenerateBill from "../pages/shop-dashboard/generate-bill/GenerateBill";
import ViewBill from "../pages/shop-dashboard/view-bill/ViewBill";
import AdminLogin from "../pages/adminSignIn/AdminLogin";
import UpdateAccountForm from "../pages/admin-dashboard/account-detail/UpdateAccountForm";


const MainRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/shop-dashboard" element={<Dashboard />}>
                    <Route path="/shop-dashboard/generate-bill" element={<GenerateBill />} />
                    <Route path="/shop-dashboard/bill-detail" element={<BillPrintView />} />
                    <Route path="/shop-dashboard/view-bill" element={<ViewBill />} />
                    {/* <Route path="/shop-dashboard/create-account" element={<AccountPage />} /> */}
                </Route>
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin-dashboard" element={<AccountPage />} />
                <Route path="/admin-dashboard/update-account" element={<UpdateAccountForm />} />
            </Routes>
        </>
    );
}

export default MainRoutes;