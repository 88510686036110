import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

let decodeValue = null;
//**********************Helper Function ********************//
(function getcokkies() {
    // Split cookie string and get all individual valueName=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        // Decode the cookie value and return
        if ("jewAdminToken" === cookiePair[0].trim()) {
            decodeValue = jwtDecode(cookiePair[1]);
            return decodeValue;
        }
    }
})();
//**********************Helper Function ********************//


const adminDetails = createSlice({
    name: "adminDetails",
    initialState: {
        userId: decodeValue?.userId ?? "",
        name: decodeValue?.name ?? "",
        email: decodeValue?.email ?? "",
        mobile: decodeValue?.mobile ?? "",
    },
    reducers: {
        addAdminDetails: (state, action) => {
            state.userId = action.payload.userDetails.userId;
            state.name = action.payload.userDetails.name;
            state.email = action.payload.userDetails.email;
            state.mobile = action.payload.userDetails.mobile;
            document.cookie = `jewAdminToken=${action.payload.token};path=/`;
        },
        removeAdminCookies: (state, action) => {
            state.name = "";
            state.email = "";
            state.userId = "";
            state.mobile = "";
            document.cookie = `jewAdminToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

        },
    },
});

export const { addAdminDetails, removeAdminCookies } = adminDetails.actions;
export default adminDetails.reducer;
