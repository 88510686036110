import React, { useEffect, useState } from 'react';
import { adminCommunication } from '../../../services/adminCommunication';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux-store/loader-slice';
import Loader from '../../../utilities/Loader';

const AccountTable = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);

    //get data on initial load
    async function getUserDetails() {
        try {
            dispatch(showLoader());
            //call API
            const serverResponse = await adminCommunication.getAllUser();
            if (serverResponse.status === "SUCCESS") {
                dispatch(hideLoader());
                setUsers(serverResponse.users)
            } else if (serverResponse.status === "JWT_INVALID") {
                dispatch(hideLoader());
                navigate("/admin/login")
                swal({ text: serverResponse.message, icon: "warning" });
            }
            else {
                dispatch(hideLoader());
                setUsers([]);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        getUserDetails();
    }, [])
    return (
        <div className="table_section account_table_section">
            {loader.loaderState === true ?
                <div className="view_loader">
                    <Loader />
                </div>
                :
                <div className="table_wrapper">
                    <div className="table_header">
                        <div className="table_item">
                            <span>SR NO</span>
                        </div>
                        <div className="table_item">
                            <span>UserId</span>
                        </div>
                        <div className="table_item">
                            <span>Shop Owner Name</span>
                        </div>
                        <div className="table_item">
                            <span>Address</span>
                        </div>
                        <div className="table_item">
                            <span>Shop Name</span>
                        </div>
                        <div className="table_item">
                            <span>GST Number</span>
                        </div>
                        <div className="table_item">
                            <span>Registration Date</span>
                        </div><div className="table_item">
                            <span>Total Days</span>
                        </div>
                        <div className="table_item">
                            <span>Amount</span>
                        </div>
                        <div className="table_item">
                            <span>Action</span>
                        </div>
                    </div>
                    {users.length > 0 ?
                        <>
                            {users.map((userDetails, index) => {
                                return (
                                    <div className="table_data" key={index}>
                                        <div className="table_item">
                                            <span>{index + 1}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.userId}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.owner}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.address}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.shopName}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.gstNumber}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{new Date(userDetails?.registrationDate).toLocaleDateString()}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.totalMonth}</span>
                                        </div>
                                        <div className="table_item">
                                            <span>{userDetails?.amount}</span>
                                        </div>
                                        <div className="table_item">
                                            <div className="button_group">
                                                <button>Renew</button>
                                                <button onClick={() => navigate(`/admin-dashboard/update-account?id=${userDetails?._id}`)}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        :
                        <div>
                            <h5>User not available</h5>
                        </div>}

                </div>
            }
            <div className="total_amount_section">
                {/* <div className="amount">
                    <h5>Net Payable</h5>
                    <InputField type="number" name="customerName" value="5888" disabled />
                </div>
                <div className="button_group">
                    <Button name="save" />
                    <Button name="print" />
                </div> */}
            </div>
        </div>
    )
}

export default AccountTable;