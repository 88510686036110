import { createSlice } from "@reduxjs/toolkit";

const loader = createSlice({
    name: "loader",
    initialState: {
        loaderState: false
    },
    reducers: {
        showLoader: (state, action) => {
            state.loaderState = true
        },
        hideLoader: (state, action) => {
            state.loaderState = false
        },
    },
});

export const { showLoader, hideLoader } = loader.actions;
export default loader.reducer;