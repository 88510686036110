import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { removeCookies } from '../redux-store/user-slice';

const TopHeader = () => {
    const userDetails = useSelector(state => state.userDetails);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    async function logout() {
        try {
            swal({
                title: "Are you sure?",
                text: `You want to logout `,
                icon: "warning",
                buttons: ["No, cancel it!", "Yes, I am sure!"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    dispatch(removeCookies());
                    navigate("/")
                } else {
                    return;
                }
            })
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }
    return (
        <div className="header_main">
            <div className="header">
                <div className="tabs_main">
                    <NavLink to="/shop-dashboard/generate-bill">
                        <div className="tab">
                            <h6>Bill Generate</h6>
                        </div>
                    </NavLink>
                    <NavLink to="/shop-dashboard/view-bill">
                        <div className="tab">
                            <h6>View Bill</h6>
                        </div>
                    </NavLink>
                    <div className="tab">
                        <h6>Support</h6>
                    </div>
                    <div className="tab">
                        <h6 onClick={() => logout()}>Logout</h6>
                    </div>
                </div>
                <div className="title">
                    <h4>{userDetails.shopName}</h4>
                </div>
                <div className="date">
                    <h5>Renewal Date :- {new Date(userDetails?.expirationDate).toLocaleDateString()}</h5>
                </div>
            </div>
        </div>
    )
}

export default TopHeader