import axios from "axios";
import swal from "sweetalert";


const developmentURL = "http://localhost:3002";
const serverUrl = process.env.REACT_APP_SERVER_URL;
const machine_IP = "http://192.168.29.129:3012";

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("jewAdminToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

function getServerUrl() {
    const nodeEnvironment = process.env.REACT_APP_NODE_ENV;

    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "machine_IP") {
        return "http://192.168.29.237:3012";
    }

    if (nodeEnvironment === "server") {
        return serverUrl;
    }

    return serverUrl;
}


export const adminCommunication = {
    login: async (userData) => {
        try {
            const response = await axios.post(`${getServerUrl()}/admin/login`, userData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getUserById: (id) => {
        try {
            return axios.post(`${getServerUrl()}/admin/get-user-by-id`, { id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAllUser: async () => {
        try {
            const response = await axios.get(`${getServerUrl()}/admin/get-all-user`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
            return response.data;
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    createUsers: async (dataToSend) => {
        try {
            const response = await axios.post(`${getServerUrl()}/admin/create-user`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
            return response.data;
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    updateUsers: async (dataToSend) => {
        try {
            const response = await axios.post(`${getServerUrl()}/admin/update-user`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
            return response.data;
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    
    
}