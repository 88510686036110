import React from 'react';
import { Outlet } from 'react-router-dom';
import TopHeader from '../../common/TopHeader';

const Dashboard = () => {
    return (
        <div className="dashboard_section">
            <TopHeader />
            <div className="routes_view">
                <Outlet></Outlet>
            </div>
        </div>
    )
}

export default Dashboard