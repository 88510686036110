import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../common/Button';
import swal from 'sweetalert';
import { communication } from '../../../services/communication';
import ReactToPrint from 'react-to-print';
import { showLoader, hideLoader } from '../../../redux-store/loader-slice';
import Loader from '../../../utilities/Loader';
import { pdfFromReact } from 'generate-pdf-from-react-html';
import convertToWords from 'convert-rupees-into-words';




const BillPrintView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const printRef = useRef();
    const loginUserDetails = useSelector(state => state.userDetails);
    const [params] = useSearchParams();
    const billId = params.get('billId');
    const [billDetail, setBillDetail] = useState({
        totalAmount: 0
    });

    const loader = useSelector((state) => state.loader);

    //Get bill by id
    const fetchBillById = async () => {
        dispatch(showLoader());
        try {
            const responseFromServer = await communication.getBillById(billId);
            if (responseFromServer?.status === "SUCCESS") {
                dispatch(hideLoader());
                setBillDetail(responseFromServer?.bill);
            } else if (responseFromServer?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.message, icon: "warning" });
                navigate("/");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchBillById();



    }, []);


    return (
        <div className="table_section bill_detail">
            <div className="bill_wrapper">
                <div className="table_header">
                    <div className="button_group">
                        <ReactToPrint trigger={() => <Button name="print bill" />}
                            content={() => printRef.current}
                            onAfterPrint={() => {
                                // pdfFromReact(".bill_table_section", `${billDetail?.invoice} Bill`, "l")
                            }}
                        />
                        <Button name="Cancel" onClick={() => navigate(-1)} />
                    </div>
                </div>
            </div>
            {loader.loaderState === true ?
                <div className="view_loader">
                    <Loader />
                </div>
                :
                <div className="bill_table_section" id='print_id' ref={printRef}>
                    <div className="letter_head"></div>
                    <div className="table_main_wrapper">
                        <div className="table_heading">
                            <h6 className="shop_name">{loginUserDetails.shopName}</h6>
                            <h6>Duplicate COPY</h6>
                        </div>
                        <div className="invoice_detail_table">
                            <div className="invoice_table_item">
                                <div className="row">
                                    <div className="row_text">
                                        <h6>Invoice No.</h6>
                                    </div>
                                    <div className="row_text">
                                        <h6>{billDetail?.invoice}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row_text">
                                        <h6>Date & Time</h6>
                                    </div>
                                    <div className="row_text">
                                        <h6>{`${new Date(billDetail?.creationDate).toLocaleDateString()} ${new Date(billDetail?.creationDate).toLocaleTimeString()}`}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="invoice_table_item">
                                <h5>TAX INVOICE</h5>
                            </div>
                            <div className="invoice_table_item">
                                <div className="row">
                                    <h6>GST No: {loginUserDetails?.gstNumber}</h6>
                                </div>
                                <div className="row">
                                    <h6>GST Type: SGST + CGST</h6>
                                </div>
                            </div>
                        </div>
                        <div className="cutomer_detail">
                            <div className="customer_detail_row">
                                <div className="title">
                                    <h6>Name</h6>
                                </div>
                                <div className="data">
                                    <h6><b>{billDetail?.customerName}</b></h6>
                                </div>
                            </div>
                            <div className="customer_detail_row">
                                <div className="title">
                                    <h6>Address</h6>
                                </div>
                                <div className="data">
                                    <h6>{billDetail?.address}</h6>
                                </div>
                            </div>
                            <div className="customer_detail_row">
                                <div className="title">
                                    <h6>Pin Code</h6>
                                </div>
                                <div className="data">
                                    {billDetail?.pinCode ?
                                        <h6>{billDetail?.pinCode}</h6>
                                        :
                                        <h6>--</h6>
                                    }
                                </div>
                            </div>
                            <div className="customer_detail_row">
                                <div className="title">
                                    <h6>Mobile No.</h6>
                                </div>
                                <div className="data">
                                    <h6>{billDetail?.mobileNumber}</h6>
                                </div>
                            </div>
                            <div className="customer_detail_row">
                                <div className="title">
                                    <h6>GST No.</h6>
                                </div>
                                <div className="data">
                                    {billDetail?.gst ?
                                        <h6>{billDetail?.gst}</h6>
                                        :
                                        <h6>--</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="item_detail_table">
                            <div className="item_table_header">
                                <div className="item">
                                    <h6>HSN Code</h6>
                                </div>
                                <div className="particular">
                                    <h6>Particulars</h6>
                                    <div className="particular_child_wrapper">
                                        <div className="purity">
                                            <h6>Purity</h6>
                                        </div>
                                        <div className="pieces">
                                            <h6>Pcs</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <h6>Gross Wt.</h6>
                                </div>
                                <div className="item">
                                    <h6>Net Wt.</h6>
                                </div>
                                <div className="item">
                                    <h6>Rate [per Gm]</h6>
                                </div>
                                <div className="item">
                                    <h6>Making</h6>
                                </div>
                                <div className="item">
                                    <h6>Amount</h6>
                                </div>
                                <div className="item cgst_wrapper">
                                    <div className="item_child">
                                        <h6>CGST</h6>
                                    </div>
                                    <div className="item_child">
                                        <div className="cgst_child_wrapper">
                                            <div className="percentage">
                                                <h6>%</h6>
                                            </div>
                                            <div className="amount">
                                                <h6>Amt</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item sgst_wrapper">
                                    <div className="item_child">
                                        <h6>SGST</h6>
                                    </div>
                                    <div className="item_child">
                                        <div className="sgst_child_wrapper">
                                            <div className="percentage">
                                                <h6>%</h6>
                                            </div>
                                            <div className="amount">
                                                <h6>Amt</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <h6>HallMarking Amount</h6>
                                </div>
                            </div>
                            {billDetail?.itemDetails?.map((item, index) => (
                                <div className="item_table_data" key={index}>
                                    <div className="item">
                                        {item?.hsnCode ?
                                            <h6>{item?.hsnCode}</h6>
                                            :
                                            <h6>-</h6>
                                        }
                                    </div>
                                    <div className="particular">
                                        <h6><b>{item?.particuler}</b></h6>
                                        <div className="particular_child_wrapper">
                                            <div className="purity">
                                                <h6>{item?.purity}</h6>
                                            </div>
                                            <div className="pieces">
                                                <h6>{item?.quantity}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        {item?.grossWeight ?
                                            <h6>{item?.grossWeight}</h6>
                                            :
                                            <h6>-</h6>
                                        }

                                    </div>
                                    <div className="item">
                                        <h6>{item?.netWeight}</h6>
                                    </div>
                                    <div className="item">
                                        <h6>{item?.ratePerGram}</h6>
                                    </div>
                                    <div className="item">
                                        <h6>{item?.makingCharges}%</h6>
                                    </div>
                                    <div className="item">
                                        <h6>{item?.amountAndMaking}</h6>
                                    </div>
                                    <div className="item cgst_wrapper">
                                        <div className="cgst_child_wrapper">
                                            <div className="percentage">
                                                <h6>{item?.cgst}</h6>
                                            </div>
                                            <div className="amount">
                                                <h6>{item?.cgstAmount}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item sgst_wrapper">
                                        <div className="sgst_child_wrapper">
                                            <div className="percentage">
                                                <h6>{item?.sgst}</h6>
                                            </div>
                                            <div className="amount">
                                                <h6>{item?.sgstAmount}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        {item?.hallMakingAmount ?
                                            <h6>{item?.hallMakingAmount}</h6>
                                            :
                                            <h6>--</h6>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="bill_amount_table">
                            <div className="bill_narration">
                                <div className="detail">
                                    <h6>Invoice Value[In Word]: <span className="amount_in_words">{convertToWords(billDetail?.totalAmount)}</span></h6>
                                </div>
                                <div className="detail">

                                    <h6>Narration:</h6>

                                </div>
                                <div className="bill_naration_detail">
                                    <h6>{billDetail?.narration}</h6>
                                </div>
                                <div className="salesman">
                                    {billDetail?.salesmanName ?
                                        <h6>Salesman:- {billDetail?.salesmanName}</h6>
                                        :
                                        <h6>Salesman:--</h6>
                                    }
                                </div>
                            </div>
                            <div className="bill_amount">
                                <div className="amount">
                                    <h6>Gross Amt.</h6>
                                    <h6>{billDetail.grossAmount}</h6>
                                </div>
                                <div className="amount">
                                    <h6>ADD CGST(1.5%)</h6>
                                    <h6>{billDetail?.totalCGST}</h6>
                                </div>
                                <div className="amount">
                                    <h6>ADD SGST(1.5%)</h6>
                                    <h6>{billDetail?.totalSGST}</h6>
                                </div>
                                <div className="amount">
                                    <h6>ADD IGST</h6>
                                    {billDetail?.totalIGST ?
                                        <h6>{billDetail?.totalIGST}</h6>
                                        :
                                        <h6>00</h6>
                                    }
                                </div>
                                <div className="amount">
                                    <h6>Less URD</h6>
                                    {
                                        billDetail?.totalUrd ?
                                            <h6>{billDetail?.totalUrd}</h6>
                                            :
                                            <h6>00</h6>
                                    }

                                </div>
                                <div className="amount">
                                    <h6>Less Disc.</h6>
                                    {billDetail?.discount ?
                                        <h6>{billDetail?.discount}</h6>
                                        :
                                        <h6>00</h6>
                                    }
                                </div>
                                <div className="amount">
                                    <h6>Round off</h6>
                                    {billDetail?.roundOff ?
                                        <h6>0.{billDetail?.roundOff}</h6>
                                        :
                                        <h6>00</h6>
                                    }
                                </div>
                                <div className="amount net_payable">
                                    <h6>Net Payable</h6>
                                    <h6>{`${billDetail?.totalAmount}.00`}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="authorization">
                            <div className="customer_section">
                                <div className="detail">
                                    {billDetail.urdDocNumber !== "" ?
                                        <h6>URD Doc No.:- {billDetail?.urdDocNumber}</h6>
                                        :
                                        <h6>URD Doc No.: --</h6>
                                    }
                                </div>
                                <div className="detail">
                                    <p>We declare that this invoice shows the actual price of the goods described and the all particulars are true and correct</p>
                                    <h6>Subject to Nagpur Jurisdiction</h6>
                                </div>
                                <div className="detail">
                                    <span>Customer Signature</span>
                                </div>
                            </div>
                            <div className="authorization_section">
                                <div className="detail">
                                    <h6>Electronic Reference No. {billDetail?.electronicReference}</h6>
                                </div>
                                <div className="detail">
                                    <h6></h6>
                                </div>
                                <div className="detail">
                                    <h6>For Shop Name</h6>
                                    <span>Authorized Signature</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer"></div>

                    <div className="original_copy">
                        <div className="letter_head"></div>
                        <div className="table_main_wrapper">
                            <div className="table_heading">
                                <h6 className="shop_name">{loginUserDetails.shopName}</h6>
                                <h6>Original COPY</h6>
                            </div>
                            <div className="invoice_detail_table">
                                <div className="invoice_table_item">
                                    <div className="row">
                                        <div className="row_text">
                                            <h6>Invoice No.</h6>
                                        </div>
                                        <div className="row_text">
                                            <h6>{billDetail?.invoice}</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="row_text">
                                            <h6>Date & Time</h6>
                                        </div>
                                        <div className="row_text">
                                            <h6>{`${new Date(billDetail?.creationDate).toLocaleDateString()} ${new Date(billDetail?.creationDate).toLocaleTimeString()}`}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice_table_item">
                                    <h5>TAX INVOICE</h5>
                                </div>
                                <div className="invoice_table_item">
                                    <div className="row">
                                        <h6>GST No: {loginUserDetails?.gstNumber}</h6>
                                    </div>
                                    <div className="row">
                                        <h6>GST Type: SGST + CGST</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="cutomer_detail">
                                <div className="customer_detail_row">
                                    <div className="title">
                                        <h6>Name</h6>
                                    </div>
                                    <div className="data">
                                        <h6><b>{billDetail?.customerName}</b></h6>
                                    </div>
                                </div>
                                <div className="customer_detail_row">
                                    <div className="title">
                                        <h6>Address</h6>
                                    </div>
                                    <div className="data">
                                        <h6>{billDetail?.address}</h6>
                                    </div>
                                </div>
                                <div className="customer_detail_row">
                                    <div className="title">
                                        <h6>Pin Code</h6>
                                    </div>
                                    <div className="data">
                                        {billDetail?.pinCode ?
                                            <h6>{billDetail?.pinCode}</h6>
                                            :
                                            <h6>--</h6>
                                        }
                                    </div>
                                </div>
                                <div className="customer_detail_row">
                                    <div className="title">
                                        <h6>Mobile No.</h6>
                                    </div>
                                    <div className="data">
                                        <h6>{billDetail?.mobileNumber}</h6>
                                    </div>
                                </div>
                                <div className="customer_detail_row">
                                    <div className="title">
                                        <h6>GST No.</h6>
                                    </div>
                                    <div className="data">
                                        {billDetail?.gst ?
                                            <h6>{billDetail?.gst}</h6>
                                            :
                                            <h6>--</h6>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="item_detail_table">
                                <div className="item_table_header">
                                    <div className="item">
                                        <h6>HSN Code</h6>
                                    </div>
                                    <div className="particular">
                                        <h6>Particulars</h6>
                                        <div className="particular_child_wrapper">
                                            <div className="purity">
                                                <h6>Purity</h6>
                                            </div>
                                            <div className="pieces">
                                                <h6>Pcs</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <h6>Gross Wt.</h6>
                                    </div>
                                    <div className="item">
                                        <h6>Net Wt.</h6>
                                    </div>
                                    <div className="item">
                                        <h6>Rate [per Gm]</h6>
                                    </div>
                                    <div className="item">
                                        <h6>Making</h6>
                                    </div>
                                    <div className="item">
                                        <h6>Amount</h6>
                                    </div>
                                    <div className="item cgst_wrapper">
                                        <div className="item_child">
                                            <h6>CGST</h6>
                                        </div>
                                        <div className="item_child">
                                            <div className="cgst_child_wrapper">
                                                <div className="percentage">
                                                    <h6>%</h6>
                                                </div>
                                                <div className="amount">
                                                    <h6>Amt</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item sgst_wrapper">
                                        <div className="item_child">
                                            <h6>SGST</h6>
                                        </div>
                                        <div className="item_child">
                                            <div className="sgst_child_wrapper">
                                                <div className="percentage">
                                                    <h6>%</h6>
                                                </div>
                                                <div className="amount">
                                                    <h6>Amt</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <h6>HallMarking Amount</h6>
                                    </div>
                                </div>
                                {billDetail?.itemDetails?.map((item, index) => (
                                    <div className="item_table_data" key={index}>
                                        <div className="item">
                                            <h6>{item?.hsnCode}</h6>
                                        </div>
                                        <div className="particular">
                                            <h6><b>{item?.particuler}</b></h6>
                                            <div className="particular_child_wrapper">
                                                <div className="purity">
                                                    <h6>{item?.purity}</h6>
                                                </div>
                                                <div className="pieces">
                                                    <h6>{item?.quantity}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            {item?.grossWeight ?
                                                <h6>{item?.grossWeight}</h6>
                                                :
                                                <h6>-</h6>
                                            }
                                        </div>
                                        <div className="item">
                                            <h6>{item?.netWeight}</h6>
                                        </div>
                                        <div className="item">
                                            <h6>{item?.ratePerGram}</h6>
                                        </div>
                                        <div className="item">
                                            <h6>{item?.makingCharges}%</h6>
                                        </div>
                                        <div className="item">
                                            <h6>{item?.amountAndMaking}</h6>
                                        </div>
                                        <div className="item cgst_wrapper">
                                            <div className="cgst_child_wrapper">
                                                <div className="percentage">
                                                    <h6>{item?.cgst}</h6>
                                                </div>
                                                <div className="amount">
                                                    <h6>{item?.cgstAmount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item sgst_wrapper">
                                            <div className="sgst_child_wrapper">
                                                <div className="percentage">
                                                    <h6>{item?.sgst}</h6>
                                                </div>
                                                <div className="amount">
                                                    <h6>{item?.sgstAmount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            {item?.hallMakingAmount ?
                                                <h6>{item?.hallMakingAmount}</h6>
                                                :
                                                <h6>--</h6>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bill_amount_table">
                                <div className="bill_narration">
                                    <div className="detail">
                                        <h6>Invoice Value[In Word]: <span className="amount_in_words">{convertToWords(billDetail?.totalAmount)}</span></h6>
                                    </div>
                                    <div className="detail">
                                        {billDetail?.narration ?
                                            <h6>Narration: {billDetail?.narration}</h6>
                                            :
                                            <h6>Narration: --</h6>
                                        }
                                    </div>
                                    <div className="bill_naration_detail">
                                        <h6>{billDetail?.paymentMode}</h6>
                                        <h6>{`${billDetail?.totalAmount}.00`}</h6>
                                    </div>
                                    <div className="salesman">
                                        {billDetail?.salesmanName ?
                                            <h6>Salesman:- {billDetail?.salesmanName}</h6>
                                            :
                                            <h6>Salesman:--</h6>
                                        }
                                    </div>
                                </div>
                                <div className="bill_amount">
                                    <div className="amount">
                                        <h6>Gross Amt.</h6>
                                        <h6>{billDetail.grossAmount}</h6>
                                    </div>
                                    <div className="amount">
                                        <h6>ADD CGST(1.5%)</h6>
                                        <h6>{billDetail?.totalCGST}</h6>
                                    </div>
                                    <div className="amount">
                                        <h6>ADD SGST(1.5%)</h6>
                                        <h6>{billDetail?.totalSGST}</h6>
                                    </div>
                                    <div className="amount">
                                        <h6>ADD IGST</h6>
                                        {billDetail?.totalIGST ?
                                            <h6>{billDetail?.totalIGST}</h6>
                                            :
                                            <h6>00</h6>
                                        }
                                    </div>
                                    <div className="amount">
                                        <h6>Less URD</h6>
                                        {
                                            billDetail?.totalUrd ?
                                                <h6>{billDetail?.totalUrd}</h6>
                                                :
                                                <h6>00</h6>
                                        }

                                    </div>
                                    <div className="amount">
                                        <h6>Less Disc.</h6>
                                        {billDetail?.discount ?
                                            <h6>{billDetail?.discount}</h6>
                                            :
                                            <h6>00</h6>
                                        }
                                    </div>
                                    <div className="amount">
                                        <h6>Round off</h6>
                                        {billDetail?.roundOff ?
                                            <h6>0.{billDetail?.roundOff}</h6>
                                            :
                                            <h6>00</h6>
                                        }
                                    </div>
                                    <div className="amount net_payable">
                                        <h6>Net Payable</h6>
                                        <h6>{`${billDetail?.totalAmount}.00`}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="authorization">
                                <div className="customer_section">
                                    <div className="detail">
                                        {billDetail.urdDocNumber !== "" ?
                                            <h6>URD Doc No.:- {billDetail?.urdDocNumber}</h6>
                                            :
                                            <h6>URD Doc No.: --</h6>
                                        }
                                    </div>
                                    <div className="detail">
                                        <p>We declare that this invoice shows the actual price of the goods described and the all particulars are true and correct</p>
                                        <h6>Subject to Nagpur Jurisdiction</h6>
                                    </div>
                                    <div className="detail">
                                        <span>Customer Signature</span>
                                    </div>
                                </div>
                                <div className="authorization_section">
                                    <div className="detail">
                                        <h6>Electronic Reference No. {billDetail?.electronicReference}</h6>
                                    </div>
                                    <div className="detail">
                                        <h6></h6>
                                    </div>
                                    <div className="detail">
                                        <h6>For Shop Name</h6>
                                        <span>Authorized Signature</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer"></div>
                    </div>
                </div>
            }

        </div>
    )
}

export default BillPrintView;