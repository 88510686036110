import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputField from '../../../common/InputField';
import Button from '../../../common/Button';
import swal from 'sweetalert';
import useValidator from '../../../utilities/useValidator';
import { adminCommunication } from '../../../services/adminCommunication';
import { showLoader, hideLoader } from '../../../redux-store/loader-slice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../utilities/Loader';

const UpdateAccountForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const id = params.get("id");
    const [validator, showValidationMessage] = useValidator();
    const loader = useSelector((state) => state.loader);

    const [data, setData] = useState(
        {
            owner: "",
            address: "",
            city: "",
            state: "",
            country: "",
            pinCode: "",
            shopName: "",
            gstNumber: "",
            registrationDate: "",
            totalMonth: "",
            amount: "",
            mobile: "",
        }
    );


    //fetch user detail by id
    const fetchUserDetailById = async (id) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await adminCommunication.getUserById(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setData(responseFromServer?.data?.user);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                navigate("/admin/login")
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    //Update Account
    const updateAccount = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());

            const dataToSend = {
                owner: data?.owner,
                address: data?.address,
                city: data?.city,
                state: data?.state,
                country: data?.country,
                pinCode: Number(data?.pinCode),
                shopName: data?.shopName,
                gstNumber: data?.gstNumber,
                registrationDate: data?.registrationDate,
                totalMonth: Number(data?.totalMonth),
                amount: data?.amount,
                mobile: data?.mobile,
                id: id
            }

            const serverResponse = await adminCommunication.updateUsers(dataToSend);
            if (serverResponse?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "success" });
                navigate(-1);
            } else if (serverResponse.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });
                navigate("/admin/login")
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });
            }
        }
        catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchUserDetailById(id);
    }, []);

    return (
        <div div className="update_account_section">
            <div className="update_account_form_wrapper">
                {loader.loaderState === true ?
                    <div className="view_loader">
                        <Loader />
                    </div>
                    :
                    <>
                        <div className="title">
                            <h4>Update Client</h4>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="owner" value={data?.owner} onChange={(event) => setData({ ...data, owner: event.target.value })} placeholder="Shop Owner Name" />
                                {validator.message("owner", data.owner, "required", {
                                    messages: {
                                        required: "Owner is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="address" value={data?.address} onChange={(event) => setData({ ...data, address: event.target.value })} placeholder="Address" />
                                {validator.message("address", data.address, "required", {
                                    messages: {
                                        required: "address is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="city" value={data?.city} onChange={(event) => setData({ ...data, city: event.target.value })} placeholder="City" />
                                {validator.message("city", data.city, "required", {
                                    messages: {
                                        required: "city is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="state" value={data?.state} onChange={(event) => setData({ ...data, state: event.target.value })} placeholder="State" />
                                {validator.message("state", data.state, "required", {
                                    messages: {
                                        required: "state is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="country" value={data?.country} onChange={(event) => setData({ ...data, country: event.target.value })} placeholder="Country" />
                                {validator.message("country", data.country, "required", {
                                    messages: {
                                        required: "country is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="pinCode" value={data?.pinCode} onChange={(event) => setData({ ...data, pinCode: event.target.value })} placeholder="PinCode" />
                                {validator.message("pinCode", data.pinCode, "required|numeric", {
                                    messages: {
                                        required: "pinCode is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="shopName" value={data?.shopName} onChange={(event) => setData({ ...data, shopName: event.target.value })} placeholder="Shop Name" />
                                {validator.message("shopName", data.shopName, "required", {
                                    messages: {
                                        required: "shopName is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="gstNumber" value={data?.gstNumber} onChange={(event) => setData({ ...data, gstNumber: event.target.value })} placeholder="GST Number" />
                                {validator.message("gstNumber", data.gstNumber, "required", {
                                    messages: {
                                        required: "gst Number is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="date" name="registrationDate" value={data?.registrationDate} onChange={(event) => setData({ ...data, registrationDate: event.target.value })} placeholder="Registration Date" />
                                {validator.message("registrationDate", data.registrationDate, "required", {
                                    messages: {
                                        required: "registration Date is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="number" name="mobile" value={data?.mobile} onChange={(event) => setData({ ...data, mobile: event.target.value })} placeholder="Mobile No" />
                                {/* {validator.message("mobile", data.mobile, "required|numeric|min:10|max:10", {
                            messages: {
                                required: "Mobile Number is required"
                            }
                        })} */}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="totalMonth" value={data?.totalMonth} onChange={(event) => setData({ ...data, totalMonth: event.target.value })} placeholder="Total Months" />
                                {validator.message("totalMonth", data.totalMonth, "required|numeric", {
                                    messages: {
                                        required: "total Days is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="amount" value={data?.amount} onChange={(event) => setData({ ...data, amount: event.target.value })} placeholder="Amount" />
                                {validator.message("amount", data.amount, "required", {
                                    messages: {
                                        required: "amount is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="button_group">
                            {loader.loaderState === false ?
                                <Button name="Update Client" onClick={() => updateAccount()} />
                                :
                                <Button><Loader /></Button>
                            }
                            <Button name="Cancel" onClick={() => navigate(-1)} />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default UpdateAccountForm