
import React, { useState, useEffect } from 'react';
import Button from '../../../common/Button'
import InputField from '../../../common/InputField'
import { useNavigate } from 'react-router-dom';
import { communication } from '../../../services/communication';
import swal from 'sweetalert';
import Loader from '../../../utilities/Loader';
import { showLoader, hideLoader } from '../../../redux-store/loader-slice';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

const ViewBill = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState(
        {
            fromDate: "",
            toDate: ""
        }
    );
    const [searchValue, setSearchValue] = useState("");
    const [bills, setBills] = useState([]);

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            searchFilterByValue(searchValue, selectedPage + 1)
        } else {
            getBills(selectedPage + 1)
        }
    };

    //Delete Bill
    async function deleteBill(billId) {
        try {
            swal({
                title: "Are you sure?",
                text: `You want to Delete Bill `,
                icon: "warning",
                buttons: ["No, cancel it!", "Yes, I am sure!"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    //call API
                    const serverResponse = await communication.deleteBills(billId);
                    if (serverResponse.status === "SUCCESS") {
                        swal({ text: serverResponse.message, icon: "success" });
                        getBills()
                    } else if (serverResponse.status === "TIME_EXPIRE") {
                        swal({ text: serverResponse.message, icon: "warning" });
                        navigate("/")
                    } else {
                        swal({ text: serverResponse.message, icon: "warning" });
                    }

                } else {
                    return;
                }
            });
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }

    //get all bills on initial load
    const getBills = async (page) => {
        try {
            dispatch(showLoader());
            //call API
            const serverResponse = await communication.getBills(page, data.fromDate, data.toDate);
            if (serverResponse.status === "SUCCESS") {
                dispatch(hideLoader());
                setBills(serverResponse.bills);
                setPageCount(serverResponse.totalPage)
            } else if (serverResponse.status === "TIME_EXPIRE") {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });
                navigate("/")
            } else {
                dispatch(hideLoader());
                // swal({ text: serverResponse.message, icon: "warning" });
                setBills([]);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });

        }

    }

    async function searchFilterByValue(searchFilter, page) {
        try {
            // dispatch(showLoader());
            //call API
            const serverResponse = await communication.searchBills(searchFilter, page);
            if (serverResponse.status === "SUCCESS") {
                dispatch(hideLoader());
                setBills(serverResponse.bills);
                setPageCount(serverResponse.totalPage)
            } else if (serverResponse.status === "TIME_EXPIRE") {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });
                navigate("/")
            } else {
                dispatch(hideLoader());
                // swal({ text: serverResponse.message, icon: "warning" });
                setBills([]);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        getBills();
    }, []);
    return (
        <div className="view_bill_section">
            <div className="view_bill_form_main">
                <div className="input_row">
                    <div className="input_box">
                        <InputField type="date" name="fromDate" onChange={(event) => setData({ ...data, fromDate: event.target.value })} />
                    </div>
                    <div className="input_box">
                        <InputField type="date" name="toDate" onChange={(event) => setData({ ...data, toDate: event.target.value })} />
                    </div>
                    <div className="input_box">
                        <Button name="Filter" onClick={() => getBills()} />
                    </div>
                </div>
            </div>
            <div className="view_bill_form_main">
                <div className="input_row">
                    <div className="input_box search_input">
                        <InputField type="search" placeholder="search....." onChange={(e) => { setSearchValue(e.target.value); searchFilterByValue(e.target.value) }} />
                    </div>
                    <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage={currentPage}
                        activeClassName={"active"} />
                </div>
            </div>

            <div className="table_section view_bill_table_section">
                {loader.loaderState === true ?
                    <div className="view_loader">
                        <Loader />
                    </div>
                    :
                    <div className="table_wrapper">
                        <div className="table_header">
                            <div className="table_item">
                                <span>Invoice No.</span>
                            </div>
                            <div className="table_item">
                                <span>Name</span>
                            </div>
                            <div className="table_item">
                                <span>Address</span>
                            </div>
                            <div className="table_item">
                                <span>Mobile No.</span>
                            </div>
                            <div className="table_item">
                                <span>GST No.</span>
                            </div>
                            <div className="table_item">
                                <span>Date & Time</span>
                            </div>
                            <div className="table_item">
                            </div>
                        </div>
                        {bills.length > 0 ?
                            <>
                                {bills.map((bill, index) => {
                                    return (
                                        <div className="table_data" key={index}>
                                            <div className="table_item">
                                                <span>{bill?.invoice}</span>
                                            </div>
                                            <div className="table_item">
                                                <span>{bill.customerName}</span>
                                            </div>
                                            <div className="table_item">
                                                <span>{bill.address}</span>
                                            </div>
                                            <div className="table_item">
                                                <span>{bill.mobileNumber}</span>
                                            </div>
                                            <div className="table_item">
                                                {bill.gst ?
                                                    <span>{bill.gst}</span>
                                                    :
                                                    <span>--</span>
                                                }
                                            </div>
                                            <div className="table_item">
                                                <span>{new Date(bill.creationDate).toLocaleDateString()}  {new Date(bill?.creationDate).toLocaleTimeString()}</span>
                                            </div>

                                            <div className="table_item">
                                                <div className="button_group">
                                                    <button onClick={() => deleteBill(bill._id)}>Delete</button>
                                                    <button onClick={() => navigate(`/shop-dashboard/bill-detail?billId=${bill?._id}`)}>View Bill</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <div className="data_not_available">
                                <h5>Data Not available</h5>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewBill