import React from 'react'
import CreateAccountForm from './CreateAccountForm'
import AccountTable from './AccountTable'

const AccountPage = () => {
    return (
        <div className="account_section">
            <div className="account_form_main">
                <CreateAccountForm />
            </div>
            <AccountTable />
        </div>
    )
}

export default AccountPage;