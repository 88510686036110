import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../common/Button';
import InputField from '../../../common/InputField'
import useValidator from '../../../utilities/useValidator'
import swal from 'sweetalert';
import { communication } from '../../../services/communication'
import Loader from '../../../utilities/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { showLoader, hideLoader } from '../../../redux-store/loader-slice';

const paymentModes = ["Cash", "Checks", "Debit cards", "Credit cards", "Online payments", "Electronic bank transfers"];

const GenerateBill = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const [validator, showValidationMessage] = useValidator();
    const [validatorItem, showValidationMessageForItem] = useValidator();
    const [billId, setBillId] = useState("");
    const [isCustomerDataSave, setIsCustomerDataSave] = useState(false)
    const [data, setData] = useState(
        {
            customerName: "",
            address: "",
            gst: "",
            pinCode: "",
            mobileNumber: "",
            salesmanName: "",
            narration: "",
            discount: "",
            electronicReference: "",
            paymentMode: "",
            urdDocNumber: "",
        }
    );
    const [item, setItem] = useState({
        purity: "",
        quantity: "",
        grossWeight: "",
        netWeight: "",
        ratePerGram: "",
        makingCharges: "",
        cgst: "",
        sgst: "",
        hallMakingAmount: "",
        hsnCode: "",
        particuler: "",
        urd: "",
        isgt: ""
    });
    const [itemDetails, setItemDetails] = useState([]);
    const [netPayableAmount, setNetPayableAmount] = useState();

    function addItem() {

        //check Validation 
        if (!validatorItem.allValid()) {
            showValidationMessageForItem(true);
            return false;
        }

        let hallMakingAmount = item?.hallMakingAmount ? item.hallMakingAmount : 0
        const amount = Number(Number((item.quantity * item.netWeight * item.ratePerGram)).toFixed(2))
        const amountAndMaking = Number(((((item.makingCharges / 100) * Number(amount)) + amount)).toFixed());
        const amountMakingAndHallmark = Number(Number(((item.makingCharges / 100) * Number(amount)) + Number(hallMakingAmount) + Number(amount)).toFixed(2))
        const cgstAmount = Number(Number(amountMakingAndHallmark) * (item.cgst / 100)).toFixed(2);

        const sgstAmount = (Number(amountMakingAndHallmark) * (item.cgst / 100)).toFixed(2)
        const itemAmount = (Number(amountMakingAndHallmark) + Number(cgstAmount) + Number(sgstAmount)).toFixed(2)
        const itemDetailsToSet = {
            amount,
            amountAndMaking,
            amountMakingAndHallmark,
            cgstAmount,
            sgstAmount,
            itemAmount,
            ...item
        }
        //add value in array
        setItemDetails([...itemDetails, itemDetailsToSet]);
        //clear all item fields
        setItem({
            purity: "",
            quantity: "",
            grossWeight: "",
            netWeight: "",
            ratePerGram: "",
            makingCharges: "",
            cgst: "",
            sgst: "",
            hallMakingAmount: "",
            hsnCode: "",
            particuler: "",
            urd: "",
            electronicReference: "",
            isgt: ""
        })
    }

    //remove the item from item details 
    function removeItem(indexToRemove) {
        try {
            const data = itemDetails.filter((element, index) => indexToRemove !== index);
            setItemDetails(data);
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }

    async function generateBill() {
        try {
            if (itemDetails.length === 0) {
                swal({ text: "Please Add Item Before to Save", icon: "warning" });
                return false;
            }
            dispatch(showLoader());
            //calculate the total CGST & SGST
            let totalCGST = 0;
            let totalSGST = 0;
            let grossAmount = 0
            itemDetails.forEach((itemDetails) => {
                totalCGST += Number(itemDetails.cgstAmount);
                totalSGST += Number(itemDetails.sgstAmount);
                grossAmount += Number(itemDetails?.amountMakingAndHallmark)
            })
            const dataToSend = {
                ...data,
                itemDetails,
                totalCGST: Number(totalCGST.toFixed(2)),
                totalSGST: Number(totalSGST.toFixed(2)),
                grossAmount: Number(grossAmount.toFixed(2)),
                roundOff: netPayableAmount.toFixed(2).toString().split(".").pop(),
                totalAmount: Number(Math.trunc(netPayableAmount))
            }
            //call API
            const serverResponse = await communication.generateBill(dataToSend);
            if (serverResponse.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "success" });
                setBillId(serverResponse?.billId);
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.message, icon: "warning" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.response.data.message || error.message, icon: "warning" });
        }
    }

    // Generate New Bill
    const generateNewBill = () => {
        setIsCustomerDataSave(false);
        setItemDetails([]);
        setNetPayableAmount(0);
        setBillId("");
        setData(
            {
                customerName: "",
                address: "",
                gst: "",
                pinCode: "",
                mobileNumber: "",
                salesmanName: "",
                narration: "",
                discount: "",
                electronicReference: "",
                paymentMode: "",
                urdDocNumber: "",
            }
        )
    }

    function savePersonalDetails() {
        //check Validation 
        if (!validator.allValid()) {
            showValidationMessage(true);
            return false;
        }
        setIsCustomerDataSave(true)
    }

    useEffect(() => {
        //calculate the net payable amount
        if (itemDetails.length > 0) {
            let total = 0;
            itemDetails.forEach((itemValue) => {
                total += Number(itemValue.itemAmount)
            });
            const amount = Number(Number(total).toFixed(2))
            setNetPayableAmount(amount)
        }
    }, [itemDetails])

    return (
        <div className="generate_bill_section">

            <div className="generate_bill_form_main">
                {/* <CustomerDetailForm />
                <ItemDetailForm /> */}
                {isCustomerDataSave === false &&
                    <>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="customerName" value={data?.customerName} onChange={(event) => setData({ ...data, customerName: event.target.value })} placeholder="Customer Name" />
                                {validator.message("customerName", data.customerName, "required", {
                                    messages: {
                                        required: "Customer Name is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="mobileNumber" value={data?.mobileNumber} onChange={(event) => setData({ ...data, mobileNumber: event.target.value })} placeholder="Mobile Number" />
                                {validator.message("mobileNumber", data.mobileNumber, "required|min:10|max:10", {
                                    messages: {
                                        required: "Mobile Number is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <select type="text" name="paymentMode" value={data?.paymentMode} onChange={(event) => setData({ ...data, paymentMode: event.target.value })} placeholder="Payment Mode" >
                                    <option value="">select payment mode</option>
                                    {
                                        paymentModes.map((mode, index) => {
                                            return (
                                                <option value={mode} key={index}>{mode}</option>
                                            )
                                        })
                                    }
                                </select>
                                {validator.message("paymentMode", data.paymentMode, "required", {
                                    messages: {
                                        required: "paymentMode is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="address" value={data?.address} onChange={(event) => setData({ ...data, address: event.target.value })} placeholder="Address" />
                                {validator.message("address", data.address, "required", {
                                    messages: {
                                        required: "address is required"
                                    }
                                })}
                            </div>

                            <div className="input_box">
                                <InputField type="number" name="pinCode" value={data?.pinCode} onChange={(event) => setData({ ...data, pinCode: event.target.value })} placeholder="PinCode" />
                            </div>

                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="narration" value={data?.narration} onChange={(event) => setData({ ...data, narration: event.target.value })} placeholder="Narration" />
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="gst" value={data?.gst} onChange={(event) => setData({ ...data, gst: event.target.value })} placeholder="Customer GST" />

                            </div>

                            <div className="input_box">
                                <InputField type="text" name="salesmanName" value={data?.salesmanName} onChange={(event) => setData({ ...data, salesmanName: event.target.value })} placeholder="salesman Name" />
                            </div>

                            <div className="input_box">
                                <InputField type="text" name="urdDocNumber" value={data?.urdDocNumber} onChange={(event) => setData({ ...data, urdDocNumber: event.target.value })} placeholder="URD Doc NO." />
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="electronicReference" value={data?.electronicReference} onChange={(event) => setData({ ...data, electronicReference: event.target.value })} placeholder="Electronic Reference" />
                            </div>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="number" name="discount" value={data?.discount} onChange={(event) => setData({ ...data, discount: event.target.value })} placeholder="Discount" />
                            </div>
                            <div className="input_box">
                                <Button name="save" onClick={() => savePersonalDetails()} />
                            </div>
                        </div>
                    </>
                }
                {isCustomerDataSave &&
                    <>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="hsnCode" value={item?.hsnCode} onChange={(event) => setItem({ ...item, hsnCode: event.target.value })} placeholder="HSN Code" />

                            </div>
                            <div className="input_box">
                                <InputField type="text" name="particular" value={item?.particuler} onChange={(event) => setItem({ ...item, particuler: event.target.value })} placeholder="Particuler" />
                                {validatorItem.message("particuler", item.particuler, "required", {
                                    messages: {
                                        required: "particular is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="purity" value={item?.purity} onChange={(event) => setItem({ ...item, purity: event.target.value })} placeholder="Purity" />
                                {validatorItem.message("purity", item.purity, "required", {
                                    messages: {
                                        required: "Purity is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="quantity" value={item?.quantity} onChange={(event) => setItem({ ...item, quantity: event.target.value })} placeholder="Quantity" />
                                {validatorItem.message("quantity", item.quantity, "required", {
                                    messages: {
                                        required: "quantity is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="grossWeight" value={item?.grossWeight} onChange={(event) => setItem({ ...item, grossWeight: event.target.value })} placeholder="Gross Weight" />
                            </div>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="number" name="netWeight" value={item?.netWeight} onChange={(event) => setItem({ ...item, netWeight: event.target.value })} placeholder="Net Weight In Gram" />
                                {validatorItem.message("netWeight", item.netWeight, "required", {
                                    messages: {
                                        required: "net Weight is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="ratePerGram" value={item?.ratePerGram} onChange={(event) => setItem({ ...item, ratePerGram: event.target.value })} placeholder="Rate Per Gram" />
                                {validatorItem.message("ratePerGram", item.ratePerGram, "required", {
                                    messages: {
                                        required: "Rate Per Gram is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="makingCharges" value={item?.makingCharges} onChange={(event) => setItem({ ...item, makingCharges: event.target.value })} placeholder="Making Charges %" />
                                {validatorItem.message("makingCharges", item.makingCharges, "required", {
                                    messages: {
                                        required: "makingCharges is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="cgst" value={item?.cgst} onChange={(event) => setItem({ ...item, cgst: event.target.value })} placeholder="CGST %" />
                                {validatorItem.message("cgst", item.cgst, "required", {
                                    messages: {
                                        required: "cgst is required"
                                    }
                                })}
                            </div>
                            <div className="input_box">
                                <InputField type="text" name="sgst" value={item?.sgst} onChange={(event) => setItem({ ...item, sgst: event.target.value })} placeholder="SGST %" />
                                {validatorItem.message("sgst", item.sgst, "required", {
                                    messages: {
                                        required: "sgst is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="input_row">
                            <div className="input_box">
                                <InputField type="text" name="hallMakingAmount" value={item?.hallMakingAmount} onChange={(event) => setItem({ ...item, hallMakingAmount: event.target.value })} placeholder="Hall Making Amount" />
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="isgt" value={item?.isgt} onChange={(event) => setItem({ ...item, isgt: event.target.value })} placeholder="IGST %" />
                            </div>
                            <div className="input_box">
                                <InputField type="number" name="urd" value={item?.urd} onChange={(event) => setItem({ ...item, urd: event.target.value })} placeholder="URD %" />
                            </div>
                            <div className="input_box">
                                <Button name="add item" onClick={() => addItem()} />
                            </div>
                            <div className="input_box"></div>
                        </div>
                    </>
                }
            </div>
            {isCustomerDataSave &&
                <div className="customer_personal_detail">
                    <span><b>Customer Name:</b> {data?.customerName}</span>
                    <span><b>Address:</b> {data?.address}</span>
                    <span><b>Mobile Number:</b> {data?.mobileNumber}</span>
                </div>
            }
            {/* <GenerateBillTable /> */}
            <div className="table_section generate_bill_table">
                <div className="table_wrapper">
                    <div className="table_header">
                        <div className="table_item">
                            <span>HSN Code</span>
                        </div>
                        <div className="table_item">
                            <span>Particular</span>
                        </div>
                        <div className="table_item">
                            <span>Purity</span>
                        </div>
                        <div className="table_item">
                            <span>Quantity</span>
                        </div>
                        <div className="table_item">
                            <span>Gross Weight</span>
                        </div>
                        <div className="table_item">
                            <span>Net Weight</span>
                        </div><div className="table_item">
                            <span>Rate [per gm]</span>
                        </div>
                        <div className="table_item">
                            <span>Making Charges %</span>
                        </div>
                        <div className="table_item">
                            <span>CGST - 1.5 %</span>
                        </div>
                        <div className="table_item">
                            <span>SGST - 1.5 %</span>
                        </div>
                        <div className="table_item">
                            <span>Hall Making Amount</span>
                        </div>
                        <div className="table_item">
                            <span>total</span>
                        </div>
                        <div className="table_item">
                            <span>Action</span>
                        </div>
                    </div>
                    {itemDetails?.map((itemValue, index) => {
                        return (
                            <div className="table_data" key={index}>
                                <div className="table_item">
                                    {
                                        itemValue?.hsnCode ?
                                            <span>{itemValue?.hsnCode}</span>
                                            :
                                            <span>--</span>
                                    }
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.particuler}</span>
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.purity}</span>
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.quantity}</span>
                                </div>
                                <div className="table_item">
                                    {itemValue?.grossWeight ?
                                        <span>{itemValue?.grossWeight}</span>
                                        :
                                        <span>--</span>
                                    }

                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.netWeight}</span>
                                </div><div className="table_item">
                                    <span>{itemValue?.ratePerGram}</span>
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.makingCharges}</span>
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.cgst}</span>
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.sgst}</span>
                                </div>
                                <div className="table_item">
                                    {itemValue?.hallMakingAmount ?
                                        <span>{itemValue?.hallMakingAmount}</span>
                                        :
                                        <span>--</span>
                                    }
                                </div>
                                <div className="table_item">
                                    <span>{itemValue?.itemAmount}</span>
                                </div>
                                <div className="table_item">
                                    <span onClick={() => removeItem(index)} title="Remove Item">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                                    </span>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div className="total_amount_section">
                    <div className="amount">
                        <h5>Net Payable</h5>
                        <InputField type="number" name="customerName" value={netPayableAmount} disabled />
                    </div>
                    <div className="button_group">
                        {billId === "" &&
                            <>
                                {loader.loaderState === false ?
                                    <Button name="save" onClick={() => generateBill()} />
                                    :
                                    <button className="button"><Loader /></button>
                                }
                            </>
                        }
                        {billId &&
                            <>
                                <Button name="Generate New Bill" onClick={() => generateNewBill()} />
                                <Button name="print" onClick={() => navigate(`/shop-dashboard/bill-detail?billId=${billId}`)} />
                            </>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GenerateBill